.surveyDetail {
    &_item {
        .ant-collapse-content {
            border: unset;
        }

        .ant-collapse-item-active {
            background-color: unset;
            box-shadow: unset;
            border: 1px solid $azureish-white !important;
        }
    }
}
