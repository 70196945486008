.t-sectionByDevice {
    background: $white;
    border-radius: rem(12);
    padding: rem(16);
    overflow: hidden;
    height: 100%;

    &_header {
        padding-bottom: rem(20);
        border-bottom: 1px solid $azureish-white;
        flex-shrink: 0;
        margin-bottom: rem(20);
        @include adjust-flex(space-between, center);
        @include mobile-down {
            flex-direction: column;
            align-items: flex-start;
        }

        &-date {
            max-width: rem(256);
            @include mobile-down {
                margin-top: rem(8);
            }
        }
    }
}

.o-deviceCircle {
    &_wrapper {
        width: 100%;
        position: relative;
        margin-bottom: rem(104);
    }

    &_list {
        @include adjust-flex(space-between, center);
        margin: rem(-20) rem(-40);
        flex-wrap: wrap;
        padding: rem(15) rem(80) rem(8);
        @include tablet-down {
            padding: rem(16) rem(40) rem(8);
        }

        @include mobile-down {
            padding: rem(16) rem(24) rem(8);
        }

        &-item {
            padding: rem(20);
            @include adjust-flex(flex-start, center);
            cursor: pointer;

            .ant-typography {
                text-transform: capitalize;
            }

            &-active,
            &:hover {
                opacity: 0.7;
                transition: opacity $transition linear;
            }
        }
        @include small-mobile {
            margin: rem(-10) rem(-20);

            &-item {
                padding: rem(10);
            }
        }
    }

    &_iconWrapper {
        width: rem(32);
        height: rem(32);
        position: relative;
        border-radius: 50%;
        margin-right: rem(12);

        &-desktop {
            background-color: $light-cobalt-blue;
        }

        &-mobile {
            background-color: $pearl-aqua;
        }

        &-tablet {
            background-color: $light-coral;
        }

        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }

    &_item {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        position: relative;
        border-style: solid;
        cursor: pointer;
        min-width: rem(160);
        max-width: 50%;
        border-width: rem(12);

        @include tablet-down {
            min-width: rem(120);
        }

        @include mobile-down {
            min-width: rem(80);
        }

        .ant-typography {
            @include desktop-up {
                @include font-base(32, 38);
            }

            @include desktop-down {
                @include font-base(24, 40);
            }
        }

        &-active,
        &:hover {
            opacity: 0.7;
            transition: opacity $transition linear;
        }

        &:nth-child(1) {
            z-index: 1;
            margin: 0 auto;
            transform: translateX(-25%);
        }

        &:nth-child(2) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 2;
        }

        &:nth-child(3) {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            z-index: 3;
        }

        &-desktop {
            background-color: $light-cobalt-blue;
            border-color: $baby-blue-eyes;
        }

        &-tablet {
            background-color: $light-coral;
            border-color: $mauvelous;
        }

        &-mobile {
            background-color: $pearl-aqua;
            border-color: $pale-robin-egg-blue;
        }

        &-content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
