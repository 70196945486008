.t-cropperImage {
    $root: &;

    &_original {
        position: relative;

        &:hover {
            #{$root}_controls {
                opacity: 1;
            }
        }
    }

    &_controls {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: $transition;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    &_container {
        position: relative;
        background-color: $white;
        display: flex;
        width: 100%;
        height: 100%;

        &-open {
            opacity: 1;
            transform: translateY(0);
        }

        &_instance {
            position: relative;
            width: 100%;
            height: calc(100% - 60px);
            flex-grow: 1;

            .cropper-bg {
                background-image: none;
                margin-left: auto;
                margin-right: auto;

                * {
                    background: none;
                }
            }
        }

        &_preview {
            overflow: hidden;
            max-width: 100%;
            width: 100%;
            background: $cool-black05;
            max-height: rem(400);

            img {
                margin-left: auto;
                margin-right: auto;
            }

            &_wrap {
                width: 30%;
                padding-left: rem(16);
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    align-self: flex-start;
                }
            }
        }

        &_title {
            margin-top: rem(16);
            text-align: center;
            color: $cool-black;
        }

        &_result {
            height: rem(500);
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: rem(16);
            position: relative;

            .ant-image {
                position: absolute;
                width: 100%;
                height: 100%;
                
                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }
        }

        &_buttons {
            margin-top: rem(16);
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: rem(-4);
            margin-right: rem(-4);

            .cropper_button {
                margin: rem(4);
                flex: 1 1 30%;
                height: auto;
                border-radius: rem(6);

                &-active,
                &:hover,
                &:focus {
                    background: $cool-black;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        &_size {
            display: flex;
            align-items: center;
            margin-top: rem(8);

            .ant {
                &-input-number {
                    flex-grow: 1;

                    input {
                        text-align: center;
                    }
                }

                &-typography {
                    margin-left: rem(8);
                    margin-right: rem(8);
                    font-weight: bold;
                }

                &-btn {
                    margin-left: rem(16);
                }
            }
        }

        &_apply {
            display: flex;
            margin: rem(16) rem(-4) 0;

            .cropper_button {
                margin: rem(4);
                flex: 1 1;
            }
        }

        &_rotates {
            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-4);
            margin-right: rem(-4);

            .cropper_button {
                margin: rem(4);
                flex: 1 1 45%;
                height: auto;
                border-radius: rem(6);

                p {
                    margin-bottom: 0;
                }

                &:hover,
                &:focus {
                    background: $cool-black;
                }
            }
        }

        &_main {
            flex-grow: 1;
            height: 100%;
            display: flex;
        }

        &_cropper {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: 100%;
            flex-shrink: 0;
            width: 70%;
            background: $cool-black05;
        }

        &_controls {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            padding: rem(8) rem(16);
            background-color: $white;
            border-radius: rem(16);

            &_size {
                margin-right: rem(36);
            }
        }

        &_resizer {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
    }

    &_info {
        margin-top: rem(16);
        align-self: flex-start;
        width: 100%;

        &_line {
            width: 100%;
            margin-top: rem(16);
        }

        p {
            margin-bottom: 0;
        }
    }

    &_result {
        flex-grow: 1;
        width: 100%;
        position: relative;

        .ant-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            display: flex;
            justify-content: center;

            img {
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}

.t-uploadModal {
    &_progress {
        margin-bottom: rem(12);
    }

    &_dragger {
        .ant-upload {
            &-list-item-done {
                color: $kelly-green;

                .ant-upload-text-icon > .anticon {
                    color: $kelly-green;
                }
            }
        }
    }

    &_preview {
        display: flex;
        flex-wrap: wrap;
        gap: rem(16);
    }

    &_image {
        flex-basis: 25%;
    }
}
  
.cropper {
    &-modal {
        background: $black !important;
    }

    &-view-box {
        outline-color: $white;
        outline-width: 2px;
    }

    &-dashed {
        border-style: solid;
        border-color: $white;
    }

    &-point {
        opacity: 1 !important;
        background: $white !important;

        &.point {
            &-se,
            &-ne,
            &-nw,
            &-sw {
                width: rem(24) !important;
                height: rem(24) !important;
            }
            
            &-se {
                clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 75%, 75% 75%, 75% 0);
            }

            &-sw {
                clip-path: polygon(25% 75%, 100% 75%, 100% 100%, 0% 100%, 0 0, 25% 0);
            }

            &-nw {
                clip-path: polygon(100% 0, 100% 25%, 25% 25%, 25% 100%, 0 100%, 0% 0%);
            }

            &-ne {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 75% 100%, 75% 25%, 0 25%);
            }

            &-e,
            &-w {
                height: rem(24);
            }

            &-n,
            &-s {
                width: rem(24);
            }
        }
    }
}

.modal-selectFile {
    position: relative;
    width: 100%;
    height: 100%;

    .ant {
        &-tabs,
        &-tabs-content,
        &-tabs-pane {
            height: 100%;
            position: relative;
        }
    }

    &_selected {
        position: relative;
        flex-basis: 33.333333%;
        max-width: rem(600);
        max-height: 100%;
        height: 100%;

        .ant-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &-img {
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &_list {
        position: relative;
        height: 100%;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: rem(16);
    }
}

.preview-video {
    width: 100%;
    height: 100%;
    background-color: $black;
}
