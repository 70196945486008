.t-quickActionCard {
    border-radius: rem(12);
    overflow: hidden;

    &_content {
        border: 1px solid $anti-flash-white;
        padding: rem(32) rem(16) rem(16);
        border-radius: 0  0 rem(12) rem(12);
    }

    &_thumbnail {
        position: relative;
    }

    &_postList {
        @include adjust-flex(center , flex-start);
        flex-wrap: wrap;
        margin: rem(-16) rem(-8);

        &-item {
            padding: rem(8);
        }
    }

    &_label {
        background: rgba($white, 0.66);
        backdrop-filter: blur(4px);
        border-radius: rem(4);
        padding: rem(4) rem(12);
        position: absolute;
        top: rem(12);
        left: rem(12);
        @include font-base(16, 24);
        font-weight: 600;
        z-index: 1;

        &-page {
            color: $queen-blue;
        }

        &-news {
            color: $iris;
        }
    }

    &_productivity {
        &-content {
            margin-top: rem(16);
            @include adjust-flex(center , center);

            .ant-typography {
                @include desktop-up {
                    @include font-base(16, 28);
                }

                @include mobile-down {
                    @include font-base(14, 20);
                }

                &:last-child {
                    margin-left: rem(4);
                }
            }
        }
    }

    &_showMore {
        color: $dodger-blue;
        @include adjust-flex(flex-end , center);
        margin-top: rem(18);
        cursor: pointer;

        &:hover {
            opacity: 0.7;
            transition: opacity $transition linear;
        }

        span {
            @include font-base(14, 20);

        }

        &-icon {
            margin-left: rem(8);
            display: flex;
            align-items: center;
        }
    }
}

.t-quickAction {
    background: $white;
    border-radius: rem(12);
    padding: rem(16);

    &_header {
        padding-bottom: rem(20);
        border-bottom: 1px solid $azureish-white;
        flex-shrink: 0;
        margin-bottom: rem(20);
    }

    &_list {
        margin: rem(-10);
        @include adjust-flex(space-between, flex-start);
        flex-wrap: wrap;

        &-item {
            padding: rem(10);
            flex: 0 0 50%;
            max-width: 50%;
            @include mobile-down {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
