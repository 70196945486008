.o-managementInfo {
    background-color: $white;
    padding: rem(24) rem(16);
    border-radius: rem(4);
    box-shadow: rgb(33 33 52 / 10%) 0 1px 4px;
    margin-bottom: rem(8);

    &:last-child {
        margin-bottom: 0;
    }

    &-item {
        @include adjust-flex(space-between, center);
        margin-bottom: rem(12);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &_selectLanguage {
        margin-top: rem(8) !important;
        min-width: rem(150);
    }
}
