.sideBar {
    $root: &;

    &_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &_menus {
        flex-grow: 1;
        overflow-y: auto;
        @include scrollbars(8, $gainsboro, transparent);
    }

    &_action {
        flex-shrink: 0;
        margin-top: rem(16);
        width: 100%;
        padding-left: rem(24);
        padding-right: rem(16);
        font-size: rem(16);
        font-weight: 500;
        color: $white;

        &-collapsed {
            padding-left: rem(5);
            padding-right: rem(5);

            #{$root}_theme, 
            #{$root}_profile,
            #{$root}__language {
                justify-content: flex-start;
            }
        }

        .ant-divider-horizontal {
            margin-top: rem(16);
            margin-bottom: rem(16);
        }
    }

    &_language {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all $transition ease-in-out;
    }

    &_languagePulldown {
        width: rem(150);

        &_option {
            vertical-align: middle;
            align-items: center;
            display: inline-flex;
            font-size: rem(14);

            .a-icon {
                display: inline-block;
            }

            > span {
                display: inline-flex;
                margin-right: rem(6);
            }
        }
    }

    &_theme {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(16);
        transition: all $transition ease-in-out;
    }

    &_profile {
        margin-top: rem(16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        transition: all $transition ease-in-out;

        button {
            padding-right: 0;
        }
    }

    &_buttonCollapse {
        appearance: none;
        background-color: transparent !important;
        border: 0 !important;
        outline: none;

        &:hover {
            background-color: transparent;
        }

        &:focus {
            background-color: transparent;
        }
    }
}

.t-menuItemEdit {
    &_avatar {
        @include adjust-flex(center, center);

        .ant-avatar-string {
            @include desktop-up {
                @include font-base(32, 38);
            }

            @include desktop-down {
                @include font-base(24, 40);
            }
        }
        margin-bottom: rem(16);

        input {
            display: none;
        }

        &-thumbnail {
            position: relative;
        }

        &-chooseFile {
            position: absolute;
            width: rem(36);
            height: rem(36);
            border: rem(2) solid $white;
            background-color: $may-green;
            border-radius: 50%;
            @include adjust-flex;
            right: rem(8);
            bottom: rem(8);
            cursor: pointer;

            &:hover {
                opacity: 0.7;
                transition: opacity $transition linear;
            }
        }

        &-icon {
            width: rem(20);
            height: rem(20);
        }
    }
}
