.container {
    position: relative;
    width: 6.857rem;
    height: 6.857rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 8px;
    cursor: pointer;
    margin-left: 8px;
}

.container:first-child {
    margin-left: 0;
}

.container:hover {
    background-color: #f5f5f5;
}

.container.active {
    background-color: #1890ff;
    color: white;
}

.container.vertical {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 2.286rem;
    padding-left: 0.571rem;
    padding-right: 0.571rem;
    margin: 4px 0;
}

.container.vertical .thumbnail {
    width: 1.714rem;
    height: 1.714rem;
    flex-shrink: 0;
}

.container.vertical .filename {
    width: auto;
    flex: 1;
    text-align: left;
    margin: 0 16px 0 1.143rem;
}

.container.vertical .date {
    flex-basis: 19%;
}

.container.vertical .size {
    flex-basis: 12%;
    margin-left: 16px;
}

.thumbnail {
    height: 5.286rem;
    width: 80%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(230, 230, 230);
    border-radius: 4px;
}

.thumbnail_image {
    display: none;
}

.filename {
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
}

.popover {
    position: absolute;
    left: 0;
    padding: 0;
    width: 0;
    visibility: hidden;
    top: 24px;
    z-index: 2;
    background-color: #fff;
    transform: translateX(0);
    overflow: hidden;
    color: #000 !important;
    box-shadow: rgb(33 33 52 / 20%) 0 1px 4px;
}

.popover.active {
    width: 90px;
    visibility: visible;
    padding: 8px 16px;
    transform: translateX(60px);
}

.popover p:last-child {
    margin-bottom: 0;
}
