.p-generateQRCode {
    margin: auto;
    display: block;
    overflow: hidden;
    position: relative;
    width: calc(100vh * 2480 / 3508);
    height: 100vh;

    svg {
        height: 100%;
        left: 50%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 60%;
        transform: translateX(-50%);
    }
}
