.t-polygonEditor {
    &_editor {
        position: relative;
        width: 100%;
        overflow: auto;
    }

    &_canvas {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
